<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- FORM START -->
      <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <browse-company class="w-full" v-validate="'required'" name="company_id" label="Company" v-model="formData.company_id"></browse-company>
            <span class="text-danger text-sm" v-show="errors.has('company_id')">{{errors.first('company_id')}}</span>
          </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="name" label="Name" v-model="formData.name"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('name')">{{errors.first('name')}}</span>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <browse-contact class="w-full" :iscustomer="true" v-validate="'required'" name="customer_id" label="Customer" v-model="formData.customer_id"></browse-contact>
          <span class="text-danger text-sm" v-show="errors.has('customer_id')">{{errors.first('customer_id')}}</span>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
            <small>Period</small>
            <div class='flex align-items-center'>
                <flat-pickr :config="flatPickrConfig" class="w-full" v-model="formData.date_start"></flat-pickr>
                <b class='ml-5 mr-5'>Until</b>
                <flat-pickr :config="flatPickrConfig" class="w-full" v-model="formData.date_end"></flat-pickr>
            </div>
        </div>
      </div>

      <div class="vx-row mb-2" v-if='$route.params.id'>
        <div class="vx-col w-full">
                <small>Active Price</small>
                <vs-checkbox class="w-full" v-model="formData.is_active"></vs-checkbox>
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="Description" v-model="formData.description"></vs-input>
        </div>
      </div>
      <!-- FORM END -->
    </div>
    <vs-divider></vs-divider>
    <div class="vx-col w-full flex">
      <vs-button @click="store" color="primary" icon-pack="feather" icon="icon-save">Save</vs-button>&nbsp;
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import BrowseCompany from '@browse/Company.vue'
import BrowseContact from '@browse/Contact.vue'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  components:{
    flatPickr,
    BrowseCompany,
    BrowseContact,
  },
  props:{
    id: {
      type: String,
      default: null
    }
  },
  data(){
    return {
        formData : {},
        flatPickrConfig: {
            altFormat: 'j M Y',
            altInput: true,
            dateFormat: 'Y-m-d',
        },
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'contracts/store',
      dispatchUpdate: 'contracts/update',
      dispatchShow: 'contracts/show'
    }),
    store(){
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        let payload = this.formData
        try {
          this.$vs.loading()
          if (this.id) {
            let {data} = await this.dispatchUpdate(payload)
            this.$emit('on-success', data)            
          } else {
            let {data} = await this.dispatchStore(payload)
            this.$emit('on-success', data)
          }
          this.$vs.loading.close()
        } catch (error) {
          this.$vs.loading.close()
          if(error.status == 422) {
                for(let x in error.data) {
                    this.$vs.notify({
                        title: 'Oops!',
                        text: error.data[x][0],
                        color: 'danger'
                    })
                    break
                }
            } else {
                if(error.data) {
                    this.$vs.notify({
                        title: 'Oops!',
                        text: error.data.message,
                        color: 'danger'
                    })
                } else {
                    this.$vs.notify({
                        title: 'Oops!',
                        text: JSON.stringify(error),
                        color: 'danger'
                    })                    
                }
            }
        }
      })
    },
    async getDetail(){
      let { data } = await this.dispatchShow(this.id)
      this.formData = data
    }
  },
  mounted(){
    // console.log(this.ids)
    if (this.id) {
      this.getDetail()
    }
  }
}
</script>

<style>

</style>